import { Atom, SHARED_VALIDATIONS } from ":mods";

export function createExperienceFirstPageInputs() {
  const GoodFitDescription = Atom.Form.createTextArea("", {
    required: true,
  });
  const JobTitle = Atom.Form.createInput("", {
    required: true,
  });
  const CV = Atom.Form.createInputFile(undefined, {
    required: false,
    validators: (v) => {
      if (!v) return undefined;
      else if (typeof v === "string") return undefined;
      const file = v[0];
      const is_safe_size = SHARED_VALIDATIONS.isFileSizeLessThan(file, 5, "MB");
      return !is_safe_size ? undefined : { [is_safe_size]: true };
    },
  });

  const Actions = Atom.Form.createFormActions(
    {
      GoodFitDescription,
      JobTitle,
      CV,
    },
    {
      defaultCase: "snake",
    }
  );
  return {
    Actions,
    GoodFitDescription,
    JobTitle,
    CV,
  };
}
